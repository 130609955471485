<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="orgData.logo"
            :text="avatarText(orgData.name)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                  :href="orgData.domain"
                  target="_blank"
                >
                  {{ orgData.name }}
                </b-link>
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <div class="card-text">{{ orgData.description }}</div>
            </div>
            <b-button
                v-if="getStorageUserData.user_type.isAdmin || getStorageUserData.user_type.isSuper"
                :to="{ name: 'organization-settings'}"
                variant="primary"
                class="mt-2 btn-sm"
              >
                Edit
              </b-button>
          </div>
        </div>

      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50 w-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge
                pill
                :variant="`light-${resolveStatusVariant(orgData.is_approved, orgData.is_archived)}`"
                class="text-capitalize"
              >
                {{ showStatus(orgData.is_approved, orgData.is_archived) }}
              </b-badge>
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact Email</span>
            </th>
            <td class="pb-50">
              {{ orgData.contact_email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact Phone</span>
            </th>
            <td class="pb-50">
              {{ orgData.contact_phone }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact Address</span>
            </th>
            <td class="pb-50 ">
              {{ orgData.contact_address }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BLink, BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useList from '../list/useList'
import { getStorageUserData } from '@/auth/utils'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BLink, BBadge,
  },
  props: {
    orgData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveStatusVariant, showStatus } = useList()

    return {
      avatarText,
      resolveStatusVariant,
      showStatus,
      getStorageUserData,
    }
  },
}
</script>

<style>

</style>
